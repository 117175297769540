document.addEventListener(
  'DOMContentLoaded',
  () => {
  },
  false
);


const header = document.querySelector('.header') || null;
const footer = document.querySelector('.footer') || null;
const cnav = document.querySelector('.c-nav') || null;
let tmpY;

if (header) {
  window.onscroll = function() {
    if (window.pageYOffset > 200) {
      header.classList.add('scrolled');
    }
    else {
      header.classList.remove('scrolled');
    }

    if ((document.body.scrollHeight - 200) < (window.pageYOffset + window.innerHeight)) {
      cnav.classList.add('unstick');
    } else {
      cnav.classList.remove('unstick');
    }
  }
}

const spHeader = document.querySelector('.header-sp') || null;
const headerToggle = document.querySelector('.js-header-toggle') || null;

if (spHeader) {
  headerToggle.addEventListener('click', function(e) {
    spHeader.classList.toggle('toggled');
    document.body.classList.toggle('overflow');
  });
}

const arrFaqItems = document.querySelectorAll('.faq__item') || null;

if (arrFaqItems) {
  [].forEach.call(arrFaqItems, function(faqItem) {
    faqItem.addEventListener('click', function(e) {
      e.preventDefault();
      
      this.classList.toggle('toggled');
    });
  });
}

// const schoolSliderHook = document.querySelector('.school__slider-container') || null;
// const schoolSlider = document.querySelector('.school__slider') || null;

// if (schoolSlider) {
//   // let tmp = schoolSlider.cloneNode(true);
//   let clone = null;
//   let sliderChildren = schoolSlider.children;

//   // [].forEach.call(sliderChildren, function(child) {
//   //   let tmpChild = child.cloneNode(true);
//   //   schoolSlider.appendChild(tmpChild);
//   // });

//   clone = document.querySelector('.school__slider').cloneNode(true);
//   clone.classList.add('clone');
//   schoolSliderHook.appendChild(clone);
// }
//   Variables
// const schoolSlider = document.querySelector('.school__slider') || null;
// let flickity = null;
// let isPaused = false;
// const slideshowEl = document.querySelector('.js-slideshow');
// // Play with this value to change the speed
// let tickerSpeed = 1;

// //   Functions
// const update = () => {
//   if (isPaused) return;
//   if (flickity.slides) {
//     flickity.x = (flickity.x - tickerSpeed) % flickity.slideableWidth;
//     flickity.selectedIndex = flickity.dragEndRestingSelect();
//     flickity.updateSelectedSlide();
//     flickity.settle(flickity.x);
//   }
//   window.requestAnimationFrame(update);
// };

// const pause = () => {
//   isPaused = true;
// };

// const play = () => {
//   if (isPaused) {
//     isPaused = false;
//     window.requestAnimationFrame(update);
//   }
// };

// //   Create Flickity

// if (schoolSlider) {
//   // new Flickity(schoolSlider, {
//   //   draggable: true,
//   //   wrapAround: true,
//   //   resize: true,
//   //   pageDots: false,
//   //   prevNextButtons: false,
//   //   autoPlay: 4000,
//   //   freeScroll: true,
//   //   pauseAutoPlayOnHover: false
//   // });

//   flickity = new Flickity(schoolSlider, {
//     draggable: true,
//     wrapAround: true,
//     resize: true,
//     pageDots: false,
//     prevNextButtons: false,
//     autoPlay: 4000,
//     freeScroll: true,
//     pauseAutoPlayOnHover: false,
//     selectedAttraction: 0.015,
//     friction: 0.25
//   });
//   flickity.x = 0;

//   //   Add Event Listeners
//   schoolSlider.addEventListener('mouseenter', pause, false);
//   schoolSlider.addEventListener('focusin', pause, false);
//   schoolSlider.addEventListener('mouseleave', play, false);
//   schoolSlider.addEventListener('focusout', play, false);
  
//   flickity.on('dragStart', () => {
//     isPaused = true;
//   });
// }


// //   Start Ticker

// update();